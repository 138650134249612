import React from "react";
import { Col } from "react-bootstrap";

import "./ProductCard.css";

export default function ProductCard(props) {
  return (
    <Col md={"4"} xs={"12"} sm={"12"} key={props.producto.id} style={{ marginBottom: "2em",display: "flex", flexDirection: "column", justifyContent: "center"}}>
      <img src={props.producto.indexImage} alt={"bf" + props.producto.id} width="640" height="460" className="card-img-top" />
      <div style={{width: "96%", marginTop: "-18px", zIndex: "9", textAlign: "left", paddingTop: "10px", paddingBottom: "10px", backgroundColor: "#303030", height: "250px", position: "relative"}}>
        <p className="card-subtitle">Oferta Excusiva PreBlackFriday</p>
        <div style={{height: "80px", marginTop: "5px"}}>
          <h3 style={{marginLeft: "15px", marginRight: "15px", textTransform: "uppercase", color: "#9c927f", backgroundColor: "transparent"}}>
            {props.producto.nombre}
          </h3>
        </div>
        <p className="price">
          {props.producto.precio_desc}€
          <span style={{textDecoration: "line-through", fontWeight: "400", marginLeft: "15px"}}>
            {props.producto.precio_normal}€
          </span>
        </p>
        <p className="sub-price">
          IVA INCL.
          <br /> {props.producto.descripcion_short}
        </p>
      </div>
      <div className="botones" style={{cursor: 'pointer'}} onClick={() => window.location.href = '/product/' + props.producto.id}>
        VER DETALLE
      </div>
    </Col>
  );
}
