import { Accordion, Container } from "react-bootstrap";
import Footer from "../../Component/Footer/Footer";

import products from '../../utils/products.json';

import './singleproduct.css';

const SingleProduct = () => {
    const productId = window.location.pathname.split('/')[2];
    const product = products.find(product => product.id === parseInt(productId));

    if(!product) return <h1 style={{color: 'white'}}>Cargando..</h1>

    return <>
        <Container className="text-center pb-5">
            <nav className="navbar"></nav>
            <div id="product" className="container-fluid container-md item-detail product type-product post-1151 status-publish first instock product_cat-experiencia-gastronomica product_cat-marbella product_tag-lena product_tag-marbella has-post-thumbnail virtual taxable product-type-simple">
                <div className="row g-md-0">
                    <div className="col-12 p-0 mb-md-3 mb-4 woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-4 images">
                        <figure className="woocommerce-product-gallery__wrapper mb-0" style={{height: "510px"}}>
                            <div className="woocommerce-product-gallery__image--placeholder--">
                                <img src={product.frontImageDesktop} alt={product.nombre} className="w-100 d-none d-md-block" style={{height: '510px'}} />
                                <img src={product.frontImageMobile} alt={product.nombre} className="w-100 d-block d-md-none" style={{height: '510px'}} />
                            </div>
                        </figure>
                        <div className="info-producto px-md-5 pb-md-2">
                            <h1 className="product_title entry-title text-white h3-detalle-prod">{product.nombre}</h1>
                            <p className="price price-title">
                                <span className="woocommerce-Price-amount amount">
                                    <bdi>{product.precio_desc}&nbsp;€</bdi>
                                    <span style={{textDecoration: "line-through", fontWeight: "400", marginLeft: "15px"}}>{product.precio_normal}€</span>
                                    <p>{product.descripcion_short}</p>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 pe-md-3">
                        <div className="marco-rest-prod p-md-4 mb-md-4">
                            <div className="text-rest-prod" style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: product.descripcion}}></div>
                            <div className="mt-4" style={{textAlign: 'left'}}>
                                {product.menu && <a href={product.menu} target="_blank" className="link-to-category text-decoration-none" rel="noopener noreferrer" style={{color: '#6b665a'}}>
                                    VER MENÚ<span class="material-icons ms-1" style={{fontSize: '1em'}}>east</span>
                                </a>}
                            </div>
                            <div className="direccion mb-4">
                                <h2 className="h2-rest-prod mb-4 mt-5" style={{textAlign: 'left'}}>
                                    {product.restaurant.name}
                                </h2>
                                <ul className="list-group list-group-horizontal">
                                    <li className="list-group-item border-0 px-0 address-icon">
                                        <span className="d-inline-block material-icons">pin_drop</span>
                                    </li>
                                    <li className="list-group-item border-0 pb-1 ps-0 address-icon" dangerouslySetInnerHTML={{__html: product.restaurant.address}}></li>
                                </ul>
                                <ul className="list-group list-group-horizontal">
                                    <li className="list-group-item border-0 px-0 address-icon">
                                        <span className="material-icons d-inline-block">info</span>
                                    </li>
                                    <li className="list-group-item border-0 pb-1 ps-0 address-icon">{product.descripcion_short}</li>
                                </ul>
                                <ul className="list-group list-group-horizontal">
                                    <li className="list-group-item border-0 px-0 address-icon">
                                        <span className="material-icons d-inline-block">receipt</span>
                                    </li>
                                    <li className="list-group-item border-0 pb-1 ps-0 address-icon">
                                        <strong>{product.precio_desc}€</strong>&nbsp;&nbsp;<span style={{textDecoration: "line-through", color: "#6b665a"}}>{product.precio_normal}€</span> IVA incl.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <Accordion defaultActiveKey="1">
                            <Accordion.Item eventKey="0" style={{backgroundColor: '#1b1c1e', border: '1px solid #9c927f', boxShadow: '0 8px 16px rgb(0 0 0 / 5%)'}}>
                                <Accordion.Header>Condiciones</Accordion.Header>
                                <Accordion.Body style={{color: '#6b665a'}}>
                                {product.conditions}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className="col-12 col-md-6 ps-md-3 mb-5 d-none- d-md-block">
                        <div className="p-md-4 sticky-top iframe-container">
                            <iframe title="Ecommerce" src={product.iframe} frameborder="0" height="1350" width="100%"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
        <Footer />
    </>
}

export default SingleProduct;