import { Container, Row } from "react-bootstrap";
import ProductCard from "./Component/ProductCard/ProductCard";
import Footer from "./Component/Footer/Footer";

import products from "./utils/products.json";

import "react-lazy-load-image-component/src/effects/blur.css";
import "./App.css";

function App() {
  const currentDay = new Date().getDate();

  return <>
    <Container className="text-center pb-5">
      <img style={{marginBottom: "15px", aspectRatio:"auto",maxWidth:"600px", width:"100%", marginTop: "50px"}} src={"./assets/PRE-BLACK-WEEKEND.png"} alt={"bfheader"} />
      {currentDay < 25 ? <>
        <h1 className="main-title">Por tu fidelidad... Disfruta de estos descuentos exclusivos para ti hasta el 24 de noviembre a las 23:59h</h1>
        <Row>
          {products.map((producto) => <ProductCard key={producto.id} producto={producto} />)}
        </Row>
      </> : <>
        <h1 className="main-title">Ha finalizado tu plazo exclusivo. Para más información de nuestro Black Weekend <a href="https://grupodanigarcia.com/tienda/" target="_blank" rel="noreferrer noopener">pincha aquí</a></h1>
      </>}
    </Container>
    <Footer />
  </>
}

export default App;
