import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import "swiper/swiper.min.css";
import "./footer.css";

const Footer = () => {
    return <footer>
        <div className="container-fluid" id="firmas-grupo">
            <div className="row" style={{color: '#FFF'}}>
                <Swiper spaceBetween={50} centeredSlides={true} speed={3000} autoplay={{delay: 0}} loop={true} slidesPerView={3} breakpoints={{
                    768: {
                        slidesPerView: 7,
                        speed: 6000
                    }
                    }} modules={[Autoplay]}>
                    <SwiperSlide>
                        <a href="https://grupodanigarcia.com/bibo/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/04/bibo.svg" className="logo-restaurant" alt="BiBo" />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://www.danibrasserie.com/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/04/dani.svg" className="logo-restaurant" alt="Dani Brasserie" />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://grupodanigarcia.com/lobito-de-mar/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/04/lobito.svg" className="logo-restaurant" alt="Lobito de Mar" />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://lagranfamiliamediterranea.com/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/04/lgfm.svg" className="logo-restaurant" alt="La Gran Familia Mediterránea" />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://www.casadanirestaurant.com/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/04/casa-dani.svg" className="logo-restaurant"  alt="Casa Dani"/>
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://grupodanigarcia.com/babette/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/04/babette.svg" className="logo-restaurant"  alt="Babette" />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://www.elpolloverde.com/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/05/ElPolloVerdenegro-7.svg" className="logo-restaurant" alt="El Pollo Verde" />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://grupodanigarcia.com/smoked-room/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/04/smoked.svg" className="logo-restaurant" alt="Smoked Room" />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://grupodanigarcia.com/lena/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/04/lena.svg" className="logo-restaurant" alt="Leña" />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://grupodanigarcia.com/aleli/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/06/Combined-Shape.svg" className="logo-restaurant" alt="Alelí" />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://grupodanigarcia.com/kemuri/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/07/logo_negro_kemuri.svg" className="logo-restaurant" alt="Kemuri" />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://grupodanigarcia.com/tragabuches/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/09/Logo-Tragabuches.png" className="logo-restaurant" alt="Tragabuches" />
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="https://grupodanigarcia.com/el-coleccionista/" target="_blank" rel="noopener noreferrer">
                            <img src="https://grupodanigarcia.com/wp-content/uploads/2022/11/logo-1.svg" className="logo-restaurant" alt="El Coleccionista" />
                        </a>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
        <div className="blog-footer container-fluid px-md-4 pt-4">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-5 order-1 order-lg-2 justify-content-end d-lg-inline-flex align-items-center">
                        <div className="mb-0">
                            <p className="d-none d-lg-inline align-self-center pe-4 mb-0 info-rrss">Síguenos: </p>
                        </div>
                        <div className="text-center text-lg-start">
                            <ul className="list-inline d-inline-flex text-center align-items-between pb-md-4 pb-lg-0 mb-lg-0 w-100">
                                <li className="list-inline-item m-auto pe-lg-2">
                                    <a href="https://www.instagram.com/accounts/login/?next=/danigarcia7/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-rrss" src="https://grupodanigarcia.com/wp-content/themes/theme-gdg/assets/dist/img/icons/rrss/instagram.svg" alt="Instagram" />
                                    </a>
                                </li>
                                <li className="list-inline-item m-auto pe-lg-2">
                                    <a href="https://vimeo.com/user35701489" target="_blank" rel="noopener noreferrer">
                                        <img className="img-rrss" src="https://grupodanigarcia.com/wp-content/themes/theme-gdg/assets/dist/img/icons/rrss/vimeo.svg" alt="Vimeo" />
                                    </a>
                                </li>
                                <li className="list-inline-item m-auto pe-lg-2">
                                    <a href="https://twitter.com/grupodanigarcia" target="_blank" rel="noopener noreferrer">
                                        <img className="img-rrss" src="https://grupodanigarcia.com/wp-content/themes/theme-gdg/assets/dist/img/icons/rrss/twitter.svg" alt="Twitter" />
                                    </a>
                                </li>
                                <li className="list-inline-item m-auto pe-lg-2">
                                    <a href="https://es-es.facebook.com/GrupoDaniGarcia/" target="_blank" rel="noopener noreferrer">
                                        <img className="img-rrss" src="https://grupodanigarcia.com/wp-content/themes/theme-gdg/assets/dist/img/icons/rrss/facebook.svg" alt="Facebook" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-lg-7 order-2 order-lg-1 mt-3 mt-md-0">
                        <ul className="nav nav-links align-items-md-center ps-md-3 navbar-nav">
                            <li className="menu-item">
                                <a href="https://grupodanigarcia.com/aviso-legal/" className="nav-link">Aviso legal</a>
                            </li>
                            <li className="menu-item">
                                <a href="https://grupodanigarcia.com/politica-de-cookies/" className="nav-link">Política de cookies</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}

export default Footer;